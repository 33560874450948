const STATUS_DELIVERED = "delivered";
const STATUS_SEND = "sent";
const STATUS_REJECTED = "rejected";
const STATUS_ERROR = "error";
const STATUS_PENDING = "pending";

export const convertServerStatus = (status) => {
  let state = "";
  switch (status) {
    case "finished":
      state = STATUS_DELIVERED;
      break;
    case "sent":
      state = STATUS_SEND;
      break;
    case "rejected":
      state = STATUS_REJECTED;
      break;
    case "in_progress":
      state = STATUS_PENDING;
      break;
    default:
      state = status;
      break;
  }
  return state;
};

export const convertServerStatusToClass = (status) => {
  let state = "";
  switch (status) {
    case "finished":
      state = STATUS_DELIVERED;
      break;
    case "sent":
      state = 'send';
      break;
    case "rejected":
      state = STATUS_REJECTED;
      break;
    case "in_progress":
      state = STATUS_PENDING;
      break;
    default:
      state = STATUS_REJECTED;
      break;
  }
  return state;
};
