import axios from "./axios";

export const loadTransactionListApi = async (filter, page, perPage) => {
  let responce = await axios.get(
    "transaction/list?" +
      (filter != "" ? "hash=" + filter : "page=" + page + "&perPage=" + perPage)
  );
  return responce.data;
};

export const loadTransactionDetailApi = async (id) => {
  let responce = await axios.get("transaction/detail?id=" + id);
  return responce.data;
};

export const loadCommonData = async () => {
  let responce = await axios.get("transaction/common-data");
  return responce.data;
};
