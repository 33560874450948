import React from "react";
import { useParams } from "react-router-dom";
import { LoadTransactionDetail } from "../hooks/LoadTransactionDetail";
import CopyButton from "../components/transactions/CopyButton";
import {
  getNetworkAddressUrl,
  getNetworkTransactionUrl,
} from "../utils/Networks";

export default function TransactionDetail() {
  const { id } = useParams();

  const { transactionData, isLoaded } = LoadTransactionDetail(id);
  if (!isLoaded || !transactionData) {
    return <></>;
  }
  return (
    <>
      <div className="page-content">
        <h1 className="page-content__title primary-title">
          Transaction details
        </h1>

        <div className="details">
          <div className="details__row">
            <div className="details__name-col">
              <span>Id:</span>
            </div>
            <div className="details__value-col">
              <div className="details__copy-cnt">
                <span>{transactionData.id}</span>
                <CopyButton copyStr={transactionData.id} />
              </div>
            </div>
          </div>
          <div className="details__row">
            <span className="details__name-col">Status</span>
            <div className="details__value-col">
              <span className={"status-badge " + transactionData.statusClass}>
                {transactionData.status}
              </span>
            </div>
          </div>
          <div className="details__row">
            <span className="details__name-col">Sub Status</span>
            <div className="details__value-col">
              {transactionData.substatus}
            </div>
          </div>
          <div className="details__row">
            <div className="details__name-col">
              <span>Source transaction hash:</span>
            </div>
            <div className="details__value-col">
              <div className="table__icon-cnt">
                <img src={transactionData.srcNetworkLogo} alt="" />
                <span className="tooltip">
                  {transactionData.srcNetworkTitle}
                </span>
              </div>
              <div className="details__copy-cnt">
                <a
                  href={getNetworkTransactionUrl(
                    transactionData.networkFrom,
                    transactionData.srcTxHash
                  )}
                  target="_blank"
                >
                  <span>{transactionData.srcTxHash}</span>
                </a>
                <CopyButton copyStr={transactionData.srcTxHash} />
              </div>
            </div>
          </div>
          <div className="details__row">
            <div className="details__name-col">
              <span>Destination transaction hash:</span>
            </div>
            <div className="details__value-col">
              <div className="table__icon-cnt">
                <img src={transactionData.dstnNetworkLogo} alt="" />
                <span className="tooltip">
                  {transactionData.dstnNetworkTitle}
                </span>
              </div>
              <div className="details__copy-cnt">
                <a
                  href={getNetworkTransactionUrl(
                    transactionData.networkTo,
                    transactionData.dstnTxHash
                  )}
                  target="_blank"
                >
                  <span>{transactionData.dstnTxHash}</span>
                </a>
                {transactionData.dstnTxHash && (
                  <CopyButton copyStr={transactionData.dstnTxHash} />
                )}
              </div>
            </div>
          </div>
          <div className="details__row">
            <div className="details__name-col">
              <span>Destination transaction error:</span>
            </div>
            <span className="details__value-col">
              {transactionData.dstnError ? transactionData.dstnError : "-"}
            </span>
          </div>
          <div className="details__row">
            <div className="details__name-col">
              <span>Source address:</span>
            </div>
            <div className="details__value-col">
              <div className="table__icon-cnt">
                <img src={transactionData.srcNetworkLogo} alt="" />
                <span className="tooltip">
                  {transactionData.srcNetworkTitle}
                </span>
              </div>
              <div className="details__copy-cnt">
                <a
                  href={getNetworkAddressUrl(
                    transactionData.networkFrom,
                    transactionData.sender
                  )}
                  target="_blank"
                >
                  <span>{transactionData.sender}</span>
                </a>
                <CopyButton copyStr={transactionData.sender} />
              </div>
            </div>
          </div>
          <div className="details__row">
            <div className="details__name-col">
              <span>Destination address:</span>
            </div>
            <div className="details__value-col">
              <div className="table__icon-cnt">
                <img src={transactionData.dstnNetworkLogo} alt="" />
                <span className="tooltip">
                  {transactionData.dstnNetworkTitle}
                </span>
              </div>
              <div className="details__copy-cnt">
                <a
                  href={getNetworkAddressUrl(
                    transactionData.networkTo,
                    transactionData.receiver
                  )}
                  target="_blank"
                >
                  <span>{transactionData.receiver}</span>
                </a>
                <CopyButton copyStr={transactionData.receiver} />
              </div>
            </div>
          </div>
          <div className="details__row">
            <div className="details__name-col">
              <span>Source block number:</span>
            </div>
            <span className="details__value-col">
              #{transactionData.srcBlockNumber}
            </span>
          </div>
          {/* <div className="details__row">
            <div className="details__name-col">
              
              <span>Destination block hash:</span>
            </div>
            <div className="details__value-col">
              <img src={transactionData.dstnNetworkLogo} alt="" />
              <div className="details__copy-cnt">
                <span>{transactionData.srcBlockHash}</span>
                <CopyButton copyStr={transactionData.srcBlockHash}/>
              </div>
            </div>
          </div> */}
          <div className="details__row">
            <div className="details__name-col">
              <span>Timestamp:</span>
            </div>
            <span className="details__value-col details__value-col_white">
              {transactionData.transactionTime}
            </span>
          </div>
          <div className="details__row">
            <div className="details__name-col">
              <span>Gas price:</span>
            </div>
            <span className="details__value-col details__value-col_white">
              {transactionData.gasPrice} ({transactionData.gasPriceUsd}$)
            </span>
          </div>
          <div className="details__row">
            <div className="details__name-col">
              <span>Transfer Hash:</span>
            </div>
            <div className="details__value-col">
              <div className="details__copy-cnt">
                <span>{transactionData.transferHash}</span>
                <CopyButton copyStr={transactionData.transferHash} />
              </div>
            </div>
          </div>
          {/* <div className="details__row">
            <div className="details__name-col">
              <span>Payload</span>
            </div>
            <div className="details__value-col details__textarea-col">
              <textarea
                className="details__textarea"
                defaultValue={transactionData.payload}
              ></textarea>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
