import { useEffect, useState, useRef } from "react";
import { timeSince } from "../utils/DateUtil";
import { loadCommonData, loadTransactionListApi } from "../app/apiMethod";
import {
  convertServerStatus,
  convertServerStatusToClass,
} from "../utils/Status";
import { getNetworkImageByChainId } from "../utils/Networks";
import { WEBSOCKET_SERVER } from "../constants/ServiceParams";
import { makeWebsocketUrl } from "../app/axios";
import moment from "moment";
import { TestTransfer, TestTransferItem } from "../constants/TestTransfer";

const TRANSACTION_COUNT = "transactionsCount";
const NETWORKS_COUNT = "networksCount";
const BYTES_SUM = "bytesSum";

const parametrs = [
  {
    key: TRANSACTION_COUNT,
    img: "/img/transactions-icon.svg",
    title: "Cross-chain transactions",
    value: null,
  },
  {
    key: NETWORKS_COUNT,
    img: "/img/networks-icon.svg",
    title: "Supported networks",
    value: null,
  },
  {
    key: BYTES_SUM,
    img: "/img/protected-icon.svg",
    title: "Bytes were transferred through relayers",
    value: null,
  },
];

export function LoadTransactionList(formData, toggleFilter) {
  const [transactions, setTransactions] = useState([]);
  const [pageData, setPageData] = useState({
    count: 0,
    total: 0,
    perPage: 14,
    currPage: 1,
    totalPageCount: 0,
    from: 0,
    to: 0,
  });
  const isLoading = useRef(false);
  const [incomeTransaction, setIncomeTransaction] = useState(null);
  const [netWorkstats, setNetworkStats] = useState(parametrs);

  const [refreshTime, setRefreshTime] = useState(false);
  const lastUpdateTime = useRef(null);
  const [loadTestTransfer, setLoadTestTransfer] = useState(false);

  const websocketService = () => {
    // console.log(makeWebsocketUrl(),'makeWebsocketUrl()')
    const ws = new WebSocket(makeWebsocketUrl());
    // const ws = new WebSocket("wss://testscan.asterizm.io/ws");
    // console.log(ws,'ws')
    ws.onopen = function (e) {
      console.log("Connection established!");
      // ws.send("Hey!");
    };

    ws.onclose = function (event) {
      if (event.wasClean) {
      } else {
      }
    };

    ws.onmessage = function (event) {
      console.log(event,'event')
      if (!event.data) {
        return;
      }
      try {
        const transaction = JSON.parse(event.data);
        setIncomeTransaction(transaction);
      } catch (err) {
        // console.log(err);
      }
    };
  };

  useEffect(() => {
    if (loadTestTransfer) {
      setIncomeTransaction(TestTransferItem);
    }
  }, [loadTestTransfer]);

  useEffect(() => {
    if (refreshTime) {
      refreshTransactionTime();
    }
  }, [refreshTime]);

  useEffect(() => {
    fillTest();
    loadCommonDataFromApi();
    websocketService();
    counter();
  }, []);

  const refreshTransactionTime = () => {
    let curTransactions = [...transactions];
    curTransactions = curTransactions.map((t) => {
      t.time = timeSince(convertServerTime(t.transactionTime));
      return t;
    });
    setTransactions(curTransactions);
    setRefreshTime(false);
  };

  const counter = () => {
    const timer = setInterval(() => {
      if (
        lastUpdateTime.current != moment(new Date()).format("YYYY-MM-DD HH:mm")
      ) {
        setRefreshTime(true);
      }
    }, 60000);
    return () => clearInterval(timer);
  };

  useEffect(() => {
    fillTest();
  }, [formData]);

  useEffect(() => {
    if (incomeTransaction) {
      refreshTransaction(incomeTransaction);
    }
  }, [incomeTransaction]);

  const refreshTransaction = (transaction) => {
    refreshUpdateTime();
    let curTransactions = [...transactions];
    let index = curTransactions.findIndex((v) => v.id == transaction.id);
    let isChanged = false;
    if (index >= 0) {
      isChanged = true;
      curTransactions.splice(index, 1, convert(transaction));
    }

    if (
      index == -1 &&
      //transaction.status == "in_progress" &&
      pageData.currPage == 1
    ) {
      isChanged = true;

      let findIndex = findIndexByTime(curTransactions, transaction);
      curTransactions.splice(findIndex, 0, convert(transaction));
      // curTransactions.unshift(convert(transaction));

      refreshParams(transaction);
    }
    if (isChanged) setTransactions(curTransactions);
  };

  const findIndexByTime = (curTransactions, transaction) => {
    var transDate = moment(transaction.transactionTime);
    let insertPos = 0;
    curTransactions.some((item, index) => {
      insertPos = index;
      if (transDate > moment(item.transactionTime)) {
        return true;
      }
    });
    return insertPos;
  };

  const refreshParams = (transaction) => {
    let stats = netWorkstats.map((v) => {
      if (v.key == TRANSACTION_COUNT) {
        v.value = (v.value || 0) + 1;
      }
      if (v.key == BYTES_SUM) {
        v.value = (v.value || 0) + transaction.bytesSum;
      }
      return v;
    });
    setNetworkStats(stats);
  };

  const refreshUpdateTime = () => {
    lastUpdateTime.current = moment(new Date()).format("YYYY-MM-DD HH:mm");
  };

  const fillTest = async () => {
    if (!formData.makeLoad || isLoading.current) return;

    isLoading.current = true;
    refreshUpdateTime();
    toggleFilter(false);
    // setLoading(true);

    try {
      let result = await loadTransactionListApi(
        formData.filter,
        formData.page,
        pageData.perPage
      );
      let transactionList = result.data.map((v) => {
        return convert(v);
      });

      setTransactions(transactionList);
      setPageData((prevState) => ({
        ...prevState,
        currPage: formData.page,
        totalPageCount: result.meta.pageCount,
        total: result.meta.totalCount,
        from: result.meta.perPage * (result.meta.page - 1) + 1,
        to:
          result.meta.perPage * (result.meta.page - 1) + transactionList.length,
      }));
    } catch (error) {}

    isLoading.current = false;
  };

  const convertServerTime = (timeStr) => {
    // console.log(moment(timeStr),moment(),'timeStr')
    // timeStr = timeStr.replace('T', ' ');
    // timeStr = timeStr.replace('Z', '');
    // console.log(timeStr,'timeStr');
    // const date = new Date(timeStr);
    return moment(timeStr); //new Date(date.toISOString().slice(0, -1));
  };

  const convert = (transaction) => {
    return {
      id: transaction.id,
      txHash: transaction.txHash,
      status: convertServerStatus(transaction.status),
      statusClass: convertServerStatusToClass(transaction.status),
      from: transaction.sender,
      fromNetworkLogo: getNetworkImageByChainId(
        parseInt(transaction.networkFrom.chainId)
      ),
      fromNetworkTitle: transaction.networkFrom.title,
      to: transaction.receiver,
      toNetworkLogo: getNetworkImageByChainId(
        parseInt(transaction.networkTo.chainId)
      ),
      toNetworkTitle: transaction.networkTo.title,
      transactionTime: transaction.transactionTime,
      time: timeSince(convertServerTime(transaction.transactionTime)),
      networkFrom: transaction.networkFrom,
      networkTo: transaction.networkTo
    };
  };

  const loadCommonDataFromApi = async () => {
    try {
      let result = await loadCommonData();
      if (result && typeof result == "object") {
        let newStats = [];
        Object.keys(result).forEach((key) => {
          let stat = netWorkstats.find((v) => v.key == key);
          if (stat) {
            stat.value = result[key];
            newStats.push(stat);
          }
        });
        setNetworkStats(newStats);
      }
    } catch (error) {}
  };

  return {
    transactions,
    pageData,
    isLoading,
    setIncomeTransaction,
    netWorkstats,
    setLoadTestTransfer,
  };
}
