import React from "react";

export default function TransactionPagination({
  pageData,
  gotoPrev,
  gotoNext,
  gotoPage,
  isLoading
}) {
  return (
    <>
      <div className="pagination">
        {pageData.total > 0 && (
          <span className="pagination__lines-counter">
            Shown  from {pageData.from} to {pageData.to} out of <b>{pageData.total}</b>
          </span>
        )}
        <div className="pagination__controls">
          <div className="pagination__input-block">
            <span>Page</span>
            <input
              className="pagination__input"
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              name=""
              value={pageData.currPage}
              onChange={(e) => gotoPage(e)}
              disabled={isLoading}
            />
            <span>of {pageData.totalPageCount}</span>
          </div>
          <div className="pagination__btns">
            <button
              className="pagination__btn pagination__btn_prev"
              disabled={pageData.currPage == 1}
              onClick={() => gotoPrev()}
            >
              <svg
                width="21"
                height="8"
                viewBox="0 0 21 8"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.3837 0.46967C16.6766 0.176777 17.1515 0.176777 17.4444 0.46967L20.4444 3.46967C20.585 3.61032 20.6641 3.80109 20.6641 4C20.6641 4.19891 20.585 4.38968 20.4444 4.53033L17.4444 7.53033C17.1515 7.82322 16.6766 7.82322 16.3837 7.53033C16.0908 7.23744 16.0908 6.76256 16.3837 6.46967L18.8534 4L16.3837 1.53033C16.0908 1.23744 16.0908 0.762563 16.3837 0.46967Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.25 4C0.25 3.58579 0.585786 3.25 1 3.25L19 3.25C19.4142 3.25 19.75 3.58579 19.75 4C19.75 4.41422 19.4142 4.75 19 4.75L1 4.75C0.585786 4.75 0.25 4.41421 0.25 4Z"
                />
              </svg>
            </button>
            <button
              className="pagination__btn pagination__btn_next"
              disabled={pageData.currPage >= pageData.totalPageCount}
              onClick={() => gotoNext()}
            >
              <svg
                width="21"
                height="8"
                viewBox="0 0 21 8"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.3837 0.46967C16.6766 0.176777 17.1515 0.176777 17.4444 0.46967L20.4444 3.46967C20.585 3.61032 20.6641 3.80109 20.6641 4C20.6641 4.19891 20.585 4.38968 20.4444 4.53033L17.4444 7.53033C17.1515 7.82322 16.6766 7.82322 16.3837 7.53033C16.0908 7.23744 16.0908 6.76256 16.3837 6.46967L18.8534 4L16.3837 1.53033C16.0908 1.23744 16.0908 0.762563 16.3837 0.46967Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.25 4C0.25 3.58579 0.585786 3.25 1 3.25L19 3.25C19.4142 3.25 19.75 3.58579 19.75 4C19.75 4.41422 19.4142 4.75 19 4.75L1 4.75C0.585786 4.75 0.25 4.41421 0.25 4Z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
